// vendors
import React, { useCallback, useEffect, useRef } from 'react';
import 'styled-components/macro';

// components
import Tooltip from '../Tooltip';

// styles
import {
  Container,
  CharactersContainer,
  TextArea,
  TextAreaWrapper,
  CharactersWrapper,
  CharactersLine,
} from './LinedTextArea.styles';

function LinedTextArea({
  value,
  meta,
  // onFocus,
  caretPosition,
  onChange,
  onClick,
  onCreateAfter,
  onSplit,
  onMerge,
  onResetCaretPosition,
  focused,
  blurred,
  faded,
  isCaptionsForTv,
  isOneSentencePerCaption,
  isCustomerVersion
}) {
  const textAreaRef = useRef(null);

  const emitChange = useCallback(
    (text) => {
      if (typeof onChange === 'function') onChange(text);
    },
    [onChange]
  );

  const emitCreateAfter = useCallback(() => {
    if (typeof onCreateAfter === 'function') onCreateAfter();
  }, [onCreateAfter]);

  const emitSplit = useCallback(
    (first, last) => {
      if (typeof onSplit === 'function') onSplit(first, last);
    },
    [onSplit]
  );

  const emitMerge = useCallback(() => {
    if (typeof onMerge === 'function') onMerge();
  }, [onMerge]);

  const emitClick = useCallback(() => {
    if (typeof onClick === 'function') onClick();
  }, [onClick]);

  const emitResetCaretPosition = useCallback(() => {
    if (typeof onResetCaretPosition === 'function') onResetCaretPosition();
  }, [onResetCaretPosition]);

  const handleChange = useCallback(
    (e) => {
      const text = e.target.value.split('\n');

      emitChange(text);
    },
    [emitChange]
  );

  const handleFocus = useCallback(
    (e) => {
      emitClick();
    },
    [emitClick]
  );

  const handleBlur = useCallback(
    (e) => {
      e.currentTarget.blur();

      if (caretPosition === -1) return;

      emitResetCaretPosition();
    },
    [caretPosition, emitResetCaretPosition]
  );

  const handleKeyDown = useCallback(
    (e) => {
      const el = e.target;
      const text = el.value;
      const start = el.selectionStart;
      const end = el.selectionEnd;

      const noModifiersPressed =
        !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey;

      if (noModifiersPressed && e.key === 'Enter') {
        e.preventDefault();

        // split text content depending on the caret position
        const firstPart = text.substring(0, start).trim().split('\n');
        const lastPart = text.substring(end, text.length).trim().split('\n');

        // create a new subtitle after the current one when the last part is empty
        const isLastPartEmpty = lastPart.every(
          (content) => content.length === 0
        );
        if (isLastPartEmpty) {
          emitCreateAfter();
          return;
        }

        // otherwise split current subtitle into two separate ones
        emitSplit(firstPart, lastPart);

        return;
      }

      if (e.shiftKey && (e.code === 'Space' || e.key === ' ')) {
        e.preventDefault();
        return;
      }

      if (
        noModifiersPressed &&
        e.key === 'Backspace' &&
        start === 0 &&
        end === 0
      ) {
        e.preventDefault();

        emitMerge();

        return;
      }
    },
    [emitCreateAfter, emitMerge, emitSplit]
  );

  useEffect(() => {
    if (!textAreaRef || !focused || caretPosition === -1) return;

    textAreaRef.current.focus();
    textAreaRef.current.setSelectionRange(caretPosition, caretPosition);
  }, [caretPosition, focused]);

  useEffect(() => {
    textAreaRef && blurred && textAreaRef.current.blur();
  }, [blurred]);

  const CharactersContent = useCallback(
    () => (
      <CharactersWrapper>
        {value.map((line, index) => (
          <>
            {index <= 2 && (
              <CharactersLine key={`char${index}`}>
                {line.length}
              </CharactersLine>
            )}
          </>
        ))}
      </CharactersWrapper>
    ),
    [value]
  );

  const lineIndexAboveCharsLimit = React.useMemo(
    () => value.findIndex((line) => (
      isCaptionsForTv && isCustomerVersion 
        ? line.length > meta.maxCharsPerLineCaptionsForTV
        : line.length > meta.maxCharsPerLine
    )),
    [meta.maxCharsPerLine, value, isCaptionsForTv, meta.maxCharsPerLineCaptionsForTV, isCustomerVersion]
  );

  const isNbLinesAboveLimit = React.useMemo(
    () => value.length > meta.maxLines,
    [meta.maxLines, value.length]
  );

  const tooltipContent = React.useMemo(
    () =>
      isNbLinesAboveLimit
        ? `It has <strong>${value.length} lines</strong>.
          Try to keep the number of lines <strong>below ${meta.maxLines + 1}</strong> for optimal reading experience.`
        : (
            isCaptionsForTv && isCustomerVersion
            ? `The line ${lineIndexAboveCharsLimit + 1} has <strong>${value[lineIndexAboveCharsLimit]?.length} characters</strong>,
              and exceeds the maximum of ${meta.maxCharsPerLineCaptionsForTV} characters per line for SCC file creation.`
            : `The line ${lineIndexAboveCharsLimit + 1} has <strong>${value[lineIndexAboveCharsLimit]?.length} characters</strong>.
              Try to keep the number of characters per line <strong>below ${meta.maxCharsPerLine + 1}</strong> for optimal reading experience.`
          ),
    [
      isNbLinesAboveLimit,
      lineIndexAboveCharsLimit,
      meta.maxCharsPerLine,
      meta.maxLines,
      meta.maxCharsPerLineCaptionsForTV,
      value,
      isCaptionsForTv,
      isCustomerVersion,
    ]
  );

  return (
    <Container>
      <CharactersContainer
        $warning={(lineIndexAboveCharsLimit >= 0 || isNbLinesAboveLimit) && isOneSentencePerCaption === false}
      >
        {(lineIndexAboveCharsLimit >= 0 || isNbLinesAboveLimit) && isOneSentencePerCaption === false ? (
          <Tooltip
            title={isCaptionsForTv && isCustomerVersion ? 'This subtitle is not valid for TV.' : 'This subtitle might not be readable.'}
            content={tooltipContent}
            minimal
          >
            <CharactersContent />
          </Tooltip>
        ) : (
          <CharactersContent />
        )}
      </CharactersContainer>

      <TextAreaWrapper>
        <TextArea
          ref={textAreaRef}
          value={value.join('\n')}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onClick={emitClick}
          $faded={faded}
        />
      </TextAreaWrapper>
    </Container>
  );
}

export default LinedTextArea;
