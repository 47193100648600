// vendors
import React, { useCallback } from 'react';

// components
import Controls from './Controls';

// context
import { useVideoPlayer } from './VideoPlayer.context.js';

// styles
import {
  CaptionTrack,
  CaptionTrackContainer,
  Container,
  PlayerWrapper,
  StyledReactPlayer,
} from './VideoPlayer.styles';
import { useSelector } from 'react-redux';

const VideoPlayer = ({ src, captions, ...rest }) => {
  const {
    muted,
    volume,
    playbackRate,
    progress,
    isReady,
    isPlaying,
    playerRef,
    setIsReady,
    setIsPlaying,
    setDuration,
    setProgress,
    hideCaption,
    goFullScreen,
    fullscreenElement,
  } = useVideoPlayer();

  const videoExists = useSelector((state) => state.srtData.present.videoExists);

  const handleReady = useCallback(() => {
    setIsReady(true);
  }, [setIsReady]);

  const handlePlay = useCallback(() => {
    if (isPlaying) return;

    setIsPlaying(true);
  }, [isPlaying, setIsPlaying]);

  const handlePause = useCallback(() => {
    if (isPlaying) return;

    setIsPlaying(false);
  }, [isPlaying, setIsPlaying]);

  const handleProgress = useCallback(
    (progress) => {
      setProgress((prev) => ({
        ...prev,
        ...progress,
      }));
    },
    [setProgress]
  );

  const handleDuration = useCallback(
    (duration) => {
      setDuration(duration);
    },
    [setDuration]
  );

  const getCaptionTrack = React.useCallback(() => {
    if (!playerRef || !playerRef.current || !isReady) return;

    const track = captions.find(
      (c) =>
        c.startTime.value < progress.playedSeconds &&
        progress.playedSeconds <= c.endTime.value
    );
    // const track = captions.find(
    //   (c) => c.start < progress.playedSeconds && progress.playedSeconds <= c.end
    // );
    return track?.lines.join('\n') || '';
  }, [captions, isReady, playerRef, progress.playedSeconds]);

  const captionTrack = React.useMemo(
    () => getCaptionTrack(),
    [getCaptionTrack]
  );

  return (
    <Container handle={goFullScreen} {...rest}>
      <PlayerWrapper>
        {!videoExists && (
          <img
            src="https://soustitreur.com/images/processingassets/audioseulement.png"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            alt="Audio Only"
          />
        )}
        <StyledReactPlayer
          id="react-player"
          ref={playerRef}
          url={src}
          volume={volume}
          muted={muted}
          playing={isPlaying}
          playbackRate={playbackRate}
          onReady={handleReady}
          onPlay={handlePlay}
          onPause={handlePause}
          onProgress={handleProgress}
          onDuration={handleDuration}
          progressInterval={60}
          controls={!videoExists ? true : false}
          width={fullscreenElement ? '100vw' : '100%'}
          height={fullscreenElement ? '100vh' : '100%'}
        />

        {!hideCaption && (
          <CaptionTrackContainer>
            <CaptionTrack $hasTrack={captionTrack}>{captionTrack}</CaptionTrack>
          </CaptionTrackContainer>
        )}
      </PlayerWrapper>
      {isReady && <Controls />}
    </Container>
  );
};

export default VideoPlayer;
