import { uniqueId } from '@blueprintjs/core/lib/esm/common/utils';
import {
  ADD_NEW_CAPTION,
  ADD_NEW_CAPTION_BETWEEN,
  CURRENT_TIME,
  DELETE_CAPTION,
  MODIFY_MULTIPLE_CAPTIONS,
  MODIFY_SINGLE_CAPTION,
  RESET_CARET_POSITION,
  FETCH_SRT_DATA_BEGIN,
  FETCH_SRT_DATA_SUCCESS,
  FETCH_SRT_DATA_FAILURE,
  SAVE_SRT_DATA_BEGIN,
  SAVE_SRT_DATA_SUCCESS,
  SAVE_SRT_DATA_FAILURE,
  EDIT_SUBTITLE,
  DELETE_SUBTITLE,
  CREATE_SUBTITLE,
  CREATE_SUBTITLE_BETWEEN,
  SPLIT_SUBTITLE,
  MERGE_SUBTITLE,
  RESET_EDIT_SINCE_LAST_SAVED,
  MODIFY_LAST_SUBTITLE_COLOR_CHANGED,
} from '../Actions';

const BUFFER_SEGMENT_TIME = 0.25;

const initialState = {
  ident: '',
  title: '',
  language: '',
  peaksUrl: null,
  videoUrl: null,
  audioLink: null,
  transcriptLogTimestamp: null,
  employeeName: null,
  employeeEmail: null,
  videoExists: true,
  vttFile: null,
  isCaptionsForTv: false,
  isOneSentencePerCaption: false,
  min1Second: false,
  currentTime: 0,
  waveformData: [],
  startMagnetSpots: [],
  endMagnetSpots: [],
  downloadOptions: {
    srt: [],
    tv: [],
    transcription: [],
  },
  // downloadsrtoptions: [],
  // downloadtvoptions: [],
  // downloadtranscriptionoptions: [],
  // corrections: {},
  // actionsLeftBeforeAutosave: 5,
  data: {
    subtitles: [],
    originallangcontent: [],
    editSinceLastSaved: false,
    lastSubtitleColorChanged: null,
  },
  meta: {
    maxCharsPerLine: 0,
    maxLines: 0,
    maxCharsPerLineCaptionsForTV: 0,
  },
  fps: null,
  status: 'idle',
  savingStatus: 'idle',
  error: null,
  duration: 0,
  iscustomerversion: false,
  samplesPerPixel: null,
  canSave: true,
};

const NAMESPACE = 'sst-subtitle';

const getFullFps = (fps) => {
  let actualFps = fps;
  switch(Math.floor(fps)) {
    case 30:
      actualFps = 30;
      break;
    case 60:
      actualFps = 60;
      break;
    case 23:
      actualFps = 24000/1001;
      break;
    case 29:
    default:
      actualFps = 30000/1001;
      break;
  }

  return actualFps;
}

const secondsToFrameNumber = (seconds, fps) => {
  return Math.round(seconds*fps);
}

const calculateWpm = (start, end, lines, fps = null) => {
  const countableLine = lines.join(' ').replace(/^(_|!|!_)[\s]{0,2}/, '');
  const nbWords = Math.round(countableLine.length / 5);

  if (fps !== null) {
    // Since SCC files use frames and integer frames are not millisecond accurate, let's calculate the WPM based on that

    fps = getFullFps(fps);

    const frameStart = secondsToFrameNumber(start, fps);
    const frameEnd = secondsToFrameNumber(end, fps);

    const cellDurationInFrames = frameEnd - frameStart;
    const frameMinute = secondsToFrameNumber(60, fps);

    return Math.ceil(nbWords / (cellDurationInFrames/frameMinute))
  }

  const cellDurationInSeconds = end - start;
  return Math.ceil(nbWords / (cellDurationInSeconds / 60));
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SRT_DATA_BEGIN:
      return {
        ...initialState,
        status: 'loading',
      };

    case FETCH_SRT_DATA_SUCCESS: {
      const subtitles = action.payload.subtitles.map((subtitle, index) => {
        const corrections =
          Object.values(action.payload.corrections).filter(
            ({ bubbleid }) => bubbleid === index
          ) || [];

        const id = uniqueId(`sst-subtitle`);

        const caretPosition = -1;

        // normalize to prevent start/end time from crossing
        let start = subtitle.start;
        if (subtitle.start > subtitle.end) {
          start = subtitle.end;
        }

        const originalLangContent = subtitle.originallangcontent || [];

        const subtitleColor = subtitle.end - subtitle.start < 1 || subtitle.end - subtitle.start > 5 ? '#ff8080' : '';

        return {
          id,
          ...subtitle,
          startTime: {
            value: start,
            updated: false,
          },
          endTime: {
            value: subtitle.end,
            updated: false,
          },
          corrections,
          caretPosition,
          originalLangContent,
          subtitleColor,
        };
      });
      const startMagnetSpots = (action.payload.magnetdata || []).starts.map(
        (num) => Number(num)
      );
      const endMagnetSpots = (action.payload.magnetdata || []).ends.map((num) =>
        Number(num)
      );

      return {
        ...initialState,
        title: action.payload.title,
        language: action.payload.lang,
        isCaptionsForTv: action.payload.isCaptionsForTv,
        isOneSentencePerCaption: action.payload.isOneSentencePerCaption,
        transcriptLogTimestamp: action.payload.transcriptLogTimestamp,
        employeeName: action.payload.employeeName,
        employeeEmail: action.payload.employeeEmail,
        currentTime: action.payload.videotimestamp,
        peaksUrl: action.payload.waveform,
        videoUrl: action.payload.videolink,
        audioLink: action.payload.audiofile,
        videoExists: action.payload.videoExists,
        ident: action.payload.ident,
        waveformData: action.payload.waveformobj,
        startMagnetSpots,
        endMagnetSpots,
        data: {
          ...state.data,
          subtitles,
          originallangcontent: action.payload.originallangcontent,
        },
        meta: {
          maxCharsPerLine: action.payload.nbcharsperline,
          maxLines: action.payload.nblines,
          maxCharsPerLineCaptionsForTV: action.payload.maxCharsPerLineCaptionsForTV,
        },
        downloadOptions: {
          srt: action.payload.downloadsrtoptions,
          tv: action.payload.downloadtvoptions,
          transcription: action.payload.downloadtranscriptionoptions,
        },
        status: 'succeeded',
        duration: Number(action.payload.duration),
        iscustomerversion: action.payload.iscustomerversion,
        samplesPerPixel: action.payload.samplesPerPixel,
        fps: action.payload.fps,
        canSave: action.payload.canSave,
      };
    }

    case FETCH_SRT_DATA_FAILURE:
      return {
        ...initialState,
        error: action.payload,
        status: 'failed',
      };

    case SAVE_SRT_DATA_BEGIN:
      return {
        ...state,
        error: null,
        savingStatus: 'loading',
      };

    case SAVE_SRT_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        savingStatus: 'succeeded',
      };

    case SAVE_SRT_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        savingStatus: 'failed',
      };

    case CREATE_SUBTITLE: {
      const subtitles = [...state.data.subtitles];

      const newSubtitle = {
        id: uniqueId(NAMESPACE),
        lines: [''],
        corrections: [],
        originalLangContent: [],
        startTime: {
          value: 0,
          updated: false,
        },
        endTime: {
          value: 0,
          updated: false,
        },
        ...action.payload.data,
      };

      const currentSubtitle = subtitles[action.payload.index];

      if (currentSubtitle) {
        newSubtitle.startTime = {
          ...newSubtitle.startTime,
          value: currentSubtitle.endTime.value,
        };

        const isCurrentTimeValid =
          state.currentTime - currentSubtitle.endTime.value >
          BUFFER_SEGMENT_TIME;

        newSubtitle.endTime = {
          ...newSubtitle.endTime,
          value: isCurrentTimeValid
            ? state.currentTime
            : newSubtitle.startTime.value + 1,
        };
      } else {
        newSubtitle.startTime = {
          ...newSubtitle.startTime,
          value: state.currentTime,
        };
        newSubtitle.endTime = {
          ...newSubtitle.endTime,
          value: state.currentTime + 1,
        };
      }

      newSubtitle.wpm = calculateWpm(
        newSubtitle.startTime.value,
        newSubtitle.endTime.value,
        newSubtitle.lines,
        state.fps
      );

      subtitles.push(newSubtitle);

      return {
        ...state,
        currentTime: newSubtitle.startTime.value,
        // actionsLeftBeforeAutosave: state.actionsLeftBeforeAutosave - 1,
        data: {
          ...state.data,
          subtitles,
          editSinceLastSaved: true,
        },
      };
    }

    case CREATE_SUBTITLE_BETWEEN: {
      const subtitles = [...state.data.subtitles];

      const currentSubtitle = subtitles[action.payload.index];

      const nextSubtitle =
        subtitles.length - 1 > action.payload.index
          ? subtitles[action.payload.index + 1]
          : null;

      if (!currentSubtitle || !nextSubtitle) return state;

      const newSubtitle = {
        id: uniqueId(NAMESPACE),
        lines: [''],
        corrections: [],
        originalLangContent: [],
        startTime: {
          value: 0,
          updated: false,
        },
        endTime: {
          value: 0,
          updated: false,
        },
        ...action.payload.data,
      };

      const isEnoughTimeRemainingBetween =
        nextSubtitle.startTime.value - currentSubtitle.endTime.value >
        BUFFER_SEGMENT_TIME;

      if (isEnoughTimeRemainingBetween) {
        const isCurrentTimeValid =
          state.currentTime - currentSubtitle.endTime.value >
            BUFFER_SEGMENT_TIME &&
          state.currentTime < nextSubtitle.startTime.value;

        newSubtitle.startTime = {
          ...newSubtitle.startTime,
          value: currentSubtitle.endTime.value,
        };
        newSubtitle.endTime = {
          ...newSubtitle.endTime,
          value: isCurrentTimeValid
            ? state.currentTime
            : nextSubtitle.startTime.value,
        };
      } else {
        currentSubtitle.endTime = {
          ...currentSubtitle.endTime,
          value: nextSubtitle.startTime.value - BUFFER_SEGMENT_TIME,
        };

        newSubtitle.startTime = {
          ...newSubtitle.startTime,
          value: currentSubtitle.endTime.value,
        };
        newSubtitle.endTime = {
          ...newSubtitle.endTime,
          value: currentSubtitle.endTime.value + BUFFER_SEGMENT_TIME,
        };
      }

      newSubtitle.wpm = calculateWpm(
        newSubtitle.startTime.value,
        newSubtitle.endTime.value,
        newSubtitle.lines,
        state.fps
      );

      subtitles.splice(action.payload.index + 1, 0, newSubtitle);

      return {
        ...state,
        currentTime:
          currentSubtitle && nextSubtitle
            ? newSubtitle.startTime.value
            : state.currentTime,
        // actionsLeftBeforeAutosave: state.actionsLeftBeforeAutosave - 1,
        data: {
          ...state.data,
          subtitles,
          editSinceLastSaved: true,
        },
      };
    }

    case EDIT_SUBTITLE: {
      const subtitles = [...state.data.subtitles];
      const linesInRealTime = action.payload.data;

      const index = subtitles.findIndex(({ id }) => id === action.payload.id);

      const currentSubtitle = subtitles[index];

      if (!currentSubtitle) return state;

      const { start, end } = action.payload.data;

      let newStartTime, newEndTime;

      if (start && start <= currentSubtitle.endTime.value) {
        newStartTime =
          start <= currentSubtitle.endTime.value - 0.1
            ? start
            : currentSubtitle.endTime.value - 0.1;

        const previousSubtitle = subtitles[index - 1];
        if (previousSubtitle) {
          newStartTime =
            start >= previousSubtitle.endTime.value
              ? newStartTime
              : previousSubtitle.endTime.value;
        }
      }

      if (end) {
        newEndTime =
          end >= currentSubtitle.startTime.value + 0.1
            ? end
            : currentSubtitle.startTime.value + 0.1;

        const nextSubtitle = subtitles[index + 1];
        if (nextSubtitle) {
          newEndTime =
            end <= nextSubtitle.startTime.value
              ? newEndTime
              : nextSubtitle.startTime.value;
        }
      }

      let wpm = 0;
      if (state.isCaptionsForTv) {
        /*let nbWords = Math.round(currentSubtitle.lines.join(' ').length / 5);
        if (linesInRealTime.lines !== undefined) {
          nbWords = Math.round(linesInRealTime.lines.join(' ').length / 5);
        }
        const cellDurationInSeconds =
          (newEndTime ?? currentSubtitle.endTime.value) -
          (newStartTime ?? currentSubtitle.startTime.value);
        //wpm = Math.ceil(nbWords / (cellDurationInSeconds / 60));*/
        wpm = calculateWpm(newStartTime ? newStartTime : currentSubtitle.startTime.value, newEndTime ? newEndTime : currentSubtitle.endTime.value, currentSubtitle.lines, state.fps);
      }

      subtitles[index] = {
        ...subtitles[index],
        ...action.payload.data,
        startTime: {
          value: newStartTime ? newStartTime : currentSubtitle.startTime.value,
          // NOTE: React state bails out of updates if the next value is the same
          // as the previous one. So We need to force the update here in order to trigger
          // a re-render of the component with the timestamp when this case happens.
          updated: !currentSubtitle.startTime.updated,
        },
        endTime: {
          value: newEndTime ? newEndTime : currentSubtitle.endTime.value,
          // NOTE: React state bails out of updates if the next value is the same
          // as the previous one. So We need to force the update here in order to trigger
          // a re-render of the component with the timestamp when this case happens.
          updated: !currentSubtitle.endTime.updated,
        },
        wpm,
      };

      return {
        ...state,
        data: {
          ...state.data,
          subtitles,
          editSinceLastSaved: true,
        },
      };
    }

    case SPLIT_SUBTITLE: {
      const subtitles = [...state.data.subtitles];

      const index = subtitles.findIndex(
        ({ id }) => id === action.payload.index
      );

      const currentSubtitle = subtitles[index];

      if (!currentSubtitle) return state;

      const isCurrentSubtitleLongEnough =
        currentSubtitle.endTime.value - currentSubtitle.startTime.value >
        2 * BUFFER_SEGMENT_TIME;

      const isCurrentTimeBetween =
        currentSubtitle.startTime.value <= state.currentTime &&
        state.currentTime <= currentSubtitle.endTime.value;

      const minTimeLength =
        currentSubtitle.startTime.value + 2 * BUFFER_SEGMENT_TIME;

      const nextSubtitle =
        subtitles.length - 1 > index ? subtitles[index + 1] : null;

      // Merge the last part of current subtitle content
      // as the first part of the next subtitle content if exist when:
      // - current time ≤ current subtitle end time
      // - difference between current time and next subtitle start time is under {2 * BUFFER_TIME}
      const hasEnoughTimeRemainingBetweenCurrentTimeAndNextSubtitle =
        nextSubtitle &&
        nextSubtitle.startTime.value - state.currentTime <
          2 * BUFFER_SEGMENT_TIME;

      let currentTime = state.currentTime;

      if (
        nextSubtitle &&
        isCurrentTimeBetween &&
        hasEnoughTimeRemainingBetweenCurrentTimeAndNextSubtitle
      ) {
        nextSubtitle.startTime = {
          ...nextSubtitle.startTime,
          value: currentSubtitle.endTime.value,
        };

        nextSubtitle.caretPosition = 0;

        if (isCurrentSubtitleLongEnough) {
          currentSubtitle.endTime = {
            ...currentSubtitle.endTime,
            value: state.currentTime,
          };

          nextSubtitle.startTime = {
            ...nextSubtitle.startTime,
            value: state.currentTime,
          };
        }

        // concat the last part from current subtitle content
        // to the first part of the next subtitle content
        const concatLastPartToNextFirstLine = [action.payload.data.last]
          .concat(nextSubtitle.lines[0])
          .join(' ');

        if (state.isCaptionsForTv) {
          currentSubtitle.wpm = calculateWpm(
            currentSubtitle.startTime.value,
            currentSubtitle.endTime.value,
            currentSubtitle.lines,
            state.fps
          );
          nextSubtitle.wpm = calculateWpm(
            nextSubtitle.startTime.value,
            nextSubtitle.endTime.value,
            nextSubtitle.lines,
            state.fps
          );
        }

        nextSubtitle.lines.splice(0, 1, concatLastPartToNextFirstLine);

        // add 0.0001 extra time to current time
        // to avoid two subtitles overlap to each other
        currentTime = nextSubtitle.startTime.value + 0.0001;
      } else {
        // otherwise create a new subtitle with the last part
        // of the current subtitle content
        const newSubtitle = {
          id: uniqueId(NAMESPACE),
          start: undefined,
          end: undefined,
          corrections: [],
          originalLangContent: [],
          lines: action.payload.data.last,
          startTime: {
            value: 0,
            updated: false,
          },
          endTime: {
            value: 0,
            updated: false,
          },
        };

        // set the new subtitle start/end time depending on :
        // - current subtitle length
        // - current time position
        if (isCurrentSubtitleLongEnough && isCurrentTimeBetween) {
          if (
            minTimeLength < state.currentTime &&
            state.currentTime <=
              currentSubtitle.endTime.value - BUFFER_SEGMENT_TIME
          ) {
            newSubtitle.startTime = {
              ...newSubtitle.startTime,
              value: state.currentTime,
            };

            newSubtitle.endTime = {
              ...newSubtitle.endTime,
              value: Math.max(
                newSubtitle.startTime.value + 2 * BUFFER_SEGMENT_TIME,
                currentSubtitle.endTime.value
              ),
            };
          } else {
            const averageTime =
              (currentSubtitle.endTime.value -
                currentSubtitle.startTime.value) /
              2;

            newSubtitle.startTime = {
              ...newSubtitle.startTime,
              value: currentSubtitle.startTime.value + averageTime,
            };
            newSubtitle.endTime = {
              ...newSubtitle.endTime,
              value: currentSubtitle.endTime.value,
            };
          }
        } else {
          newSubtitle.startTime = {
            ...newSubtitle.startTime,
            value: currentSubtitle.endTime.value,
          };
          newSubtitle.endTime = {
            ...newSubtitle.endTime,
            value: newSubtitle.startTime.value + 2 * BUFFER_SEGMENT_TIME,
          };

          if (
            state.currentTime >
            currentSubtitle.endTime.value + 2 * BUFFER_SEGMENT_TIME
          ) {
            newSubtitle.endTime = {
              ...newSubtitle.endTime,
              value: state.currentTime,
            };
          }
        }

        if (state.isCaptionsForTv) {
          newSubtitle.wpm = calculateWpm(
            newSubtitle.startTime.value,
            newSubtitle.endTime.value,
            newSubtitle.lines,
            state.fps
          );
        }

        subtitles.splice(index + 1, 0, newSubtitle);

        currentSubtitle.endTime = {
          ...currentSubtitle.endTime,
          value: newSubtitle.startTime.value,
        };

        // add 0.0001 extra time to current time
        // to avoid two subtitles overlap to each other
        currentTime = newSubtitle.startTime.value + 0.0001;

        if (state.isCaptionsForTv) {
          currentSubtitle.wpm = calculateWpm(
            currentSubtitle.startTime.value,
            currentSubtitle.endTime.value,
            currentSubtitle.lines,
            state.fps
          );
        }
      }

      subtitles[index] = {
        ...currentSubtitle,
        lines: action.payload.data.first,
      };

      if (state.isCaptionsForTv) {
        subtitles[index].wpm = calculateWpm(
          subtitles[index].startTime.value,
          subtitles[index].endTime.value,
          subtitles[index].lines,
          state.fps
        );
      }

      return {
        ...state,
        currentTime,
        // actionsLeftBeforeAutosave: state.actionsLeftBeforeAutosave - 1,
        data: {
          ...state.data,
          subtitles,
          editSinceLastSaved: true,
        },
      };
    }

    case MERGE_SUBTITLE: {
      const subtitles = [...state.data.subtitles];

      const index = subtitles.findIndex(({ id }) => id === action.payload.id);

      const currentSubtitle = subtitles[index];
      const previousSubtitle = subtitles[index - 1];

      // allow merge content from current to previous subtitle
      // when the difference time between is at most {{MERGE_BUFFER}}
      const MERGE_BUFFER = 7.0;
      const isFollowing =
        currentSubtitle?.startTime.value <=
        previousSubtitle?.endTime.value + MERGE_BUFFER;

      if (!currentSubtitle || !previousSubtitle || !isFollowing) return state;

      // check previous and current subtitle have content
      const currentContent = currentSubtitle.lines.join('\n');
      const previousContent = previousSubtitle.lines.join('\n');
      const previousHasContent = previousContent.length > 0;
      const currentHasContent = currentContent.length > 0;

      // set the caret position depending on the presence of content
      previousSubtitle.caretPosition =
        previousHasContent && currentHasContent
          ? previousContent.length + 1
          : previousContent.length;

      if (currentHasContent) {
        // concat the content of the first line from the current subtitle
        // with the content of the last line from the previous subtitle
        const previousLastLineModified = previousSubtitle.lines[
          previousSubtitle.lines.length - 1
        ]
          .split(' ')
          .concat(currentSubtitle.lines[0])
          .join(' ')
          .trim();

        // replace the last line from the previous subtitle with the new modified content
        previousSubtitle.lines.splice(
          previousSubtitle.lines.length - 1,
          1,
          previousLastLineModified
        );

        // if lines remaining from the current subtitle, concat them to the previous subtitle lines
        if (currentSubtitle.lines.length > 1) {
          previousSubtitle.lines.push(...currentSubtitle.lines.slice(1));
        }

        previousSubtitle.endTime = {
          ...previousSubtitle.endTime,
          value: currentSubtitle.endTime.value,
        };
      }

      if (state.isCaptionsForTv) {
        currentSubtitle.wpm = calculateWpm(
          currentSubtitle.startTime.value,
          currentSubtitle.endTime.value,
          currentSubtitle.lines,
          state.fps
        );
      }

      // remove merged subtitle
      subtitles.splice(index, 1);

      return {
        ...state,
        currentTime: previousSubtitle
          ? previousSubtitle.startTime.value
          : state.currentTime,
        data: {
          ...state.data,
          subtitles,
          editSinceLastSaved: true,
        },
      };
    }

    case DELETE_SUBTITLE: {
      const subtitles = [...state.data.subtitles];

      const currentIndex = subtitles.findIndex(
        ({ id }) => id === action.payload.id
      );

      const newSubtitles = subtitles.filter(
        (_, index) => index !== currentIndex
      );

      const previousSubtitle = subtitles[currentIndex - 1];
      const nextSubtitle = subtitles[currentIndex + 1];

      return {
        ...state,
        currentTime: nextSubtitle
          ? nextSubtitle.startTime.value
          : previousSubtitle
          ? previousSubtitle.startTime.value
          : state.currentTime,
        // actionsLeftBeforeAutosave: state.actionsLeftBeforeAutosave - 1,
        data: {
          ...state.data,
          subtitles: [...newSubtitles],
          editSinceLastSaved: true,
        },
      };
    }

    case RESET_CARET_POSITION: {
      const subtitles = [...state.data.subtitles];

      const index = subtitles.findIndex(({ id }) => id === action.payload.id);

      subtitles[index] = {
        ...subtitles[index],
        caretPosition: -1,
      };

      return {
        ...state,
        data: {
          ...state.data,
          subtitles,
        },
      };
    }

    case MODIFY_LAST_SUBTITLE_COLOR_CHANGED: {
      const subtitles = [...state.data.subtitles];
      const index = subtitles.findIndex(({ id }) => id === action.payload.subtitle.id);
      const currentSubtitle = subtitles[index];

      if (!currentSubtitle) return state;

      return {
        ...state,
        data: {
          ...state.data,
          subtitles,
          lastSubtitleColorChanged: currentSubtitle,
        },
      };
    }

    case MODIFY_MULTIPLE_CAPTIONS: {
      const subtitles = [...state.data.subtitles];
      const payloadSubtitles = action.payload.subtitles;

      if (state.isCaptionsForTv) {
        console.log(state.fps);
        for (let index = 0; index < payloadSubtitles.length; index++) {
          payloadSubtitles[index].subtitle.wpm = calculateWpm(
            payloadSubtitles[index].subtitle.startTime.value,
            payloadSubtitles[index].subtitle.endTime.value,
            payloadSubtitles[index].subtitle.lines,
            state.fps
          );

        }
      }

      action.payload.subtitles.forEach((subtitle) => {
        subtitles[subtitle.index] = {
          ...subtitle.subtitle,
          // NOTE: React state bails out of updates if the next value is the same
          // as the previous one. So We need to force the update here in order to trigger
          // a re-render of the component with the timestamp when this case happens.
          updated: !subtitle.subtitle.updated,
        };
      });

      return {
        ...state,
        // actionsLeftBeforeAutosave: state.actionsLeftBeforeAutosave - 1,
        data: {
          ...state.data,
          subtitles,
          editSinceLastSaved: true,
        },
      };
    }
    case CURRENT_TIME:
      return {
        ...state,
        currentTime: action.time,
      };

    case RESET_EDIT_SINCE_LAST_SAVED:
      return {
        ...state,
        data: {
          ...state.data,
          editSinceLastSaved: false,
        },
      };

    // REFACTOR: OLD ACTIONS - TO DELETE OR EDITS
    /**
     * @deprecated
     */
    case MODIFY_SINGLE_CAPTION: {
      let newSubtitles = [...state.data.subtitles];

      newSubtitles[action.subIndex] = action.newCaption;

      return {
        ...state,
        // actionsLeftBeforeAutosave: state.actionsLeftBeforeAutosave - 1,
        data: {
          ...state.data,
          subtitles: newSubtitles,
          editSinceLastSaved: true,
        },
      };
    }
    /**
     * @deprecated
     */
    case ADD_NEW_CAPTION: {
      const newSubtitles = [...state.data.subtitles];
      const backwardCaption = newSubtitles[action.subIndex];

      const splitTime =
        state.currentTime > backwardCaption.start &&
        state.currentTime < backwardCaption.end
          ? state.currentTime
          : (backwardCaption.end - backwardCaption.start) / 2 +
            backwardCaption.start;

      const oldSub = {
        start: newSubtitles[action.subIndex].start,
        end: splitTime,
        lines: action.oldCaption,
      };

      const newSub = {
        start: splitTime,
        end: backwardCaption.end,
        lines: action.newCaption,
      };

      newSubtitles[action.subIndex] = oldSub;
      newSubtitles.splice(action.subIndex + 1, 0, newSub);

      return {
        ...state,
        // actionsLeftBeforeAutosave: state.actionsLeftBeforeAutosave - 1,
        data: {
          ...state.data,
          subtitles: newSubtitles,
          editSinceLastSaved: true,
        },
      };
    }
    /**
     * @deprecated
     */
    case ADD_NEW_CAPTION_BETWEEN: {
      const newSubtitles = [...state.data.subtitles];
      const newCaption = { start: 0, end: 0, lines: [''] };
      const backwardCaption = newSubtitles[action.backwardCaptionIndex];
      const forwardCaption = newSubtitles[action.forwardCaptionIndex];

      if (forwardCaption.start - backwardCaption.end <= 0) {
        backwardCaption.end = backwardCaption.end - 0.5;
      }

      newCaption.start = backwardCaption.end;
      newCaption.end = forwardCaption.start;

      newSubtitles.splice(action.backwardCaptionIndex + 1, 0, newCaption);

      return {
        ...state,
        // actionsLeftBeforeAutosave: state.actionsLeftBeforeAutosave - 1,
        data: {
          ...state.data,
          subtitles: newSubtitles,
          editSinceLastSaved: true,
        },
      };
    }
    /**
     * @deprecated
     */
    case DELETE_CAPTION: {
      let newSubtitles = state.data.subtitles.filter(
        (_, i) => i !== action.subIndex
      );

      return {
        ...state,
        // actionsLeftBeforeAutosave: state.actionsLeftBeforeAutosave - 1,
        data: {
          ...state.data,
          subtitles: newSubtitles,
          editSinceLastSaved: true,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;

export {
  getFullFps,
  calculateWpm,
  secondsToFrameNumber,
};
